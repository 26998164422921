import { useMutation, useQuery } from '@tanstack/react-query'

import { api } from '@/api'
import { IEncounter } from '@/models'
import { SocketApi } from '@/services/SocketApi'
import { generatePdf, generatePdfFromSections } from '@/util'

const socketApi = new SocketApi()

export const useTemplates = () => useQuery({ queryKey: ['templates'], queryFn: api.getProcedureTemplates })

export const useStaticTemplates = () => useQuery({ queryKey: ['templates'], queryFn: api.getStatictemplates })

export const useNote = () => useQuery({ queryKey: ['notes'], queryFn: api.getNote })

export const useGenerateNote = () =>
  useMutation({
    mutationFn: (encounter: IEncounter) => {
      const rawTranscript = Object.values(encounter.transcript?.sections ?? {}).reduce((text, section) => {
        if (!text) return section ?? ''
        return [text, section].join('\n')
      }, '')
      const templateId =
        encounter.templateId === 'QUICK_NOTE' || encounter.templateId === 'SOAP_NOTE' ? encounter.templateId : 'NOTE'
      socketApi.generateNote(templateId, rawTranscript)
      return Promise.resolve()
    }
  })

export const useGeneratePatientSummary = () => {
  return useMutation({
    mutationFn: async (encounter: IEncounter) => {
      const rawTranscript = Object.values(encounter.transcript?.sections ?? {}).reduce((text, section) => {
        if (!text) return section ?? ''
        return [text, section].join('\n')
      }, '')
      socketApi.generatePatientSummary(rawTranscript)
      return Promise.resolve()
    }
  })
}

export const useGeneratePdf = () =>
  useMutation({
    mutationFn: (options: Parameters<typeof generatePdf>[0]) => generatePdf(options)
  })

export const useGeneratePdfFromSections = () =>
  useMutation({
    mutationFn: (options: Parameters<typeof generatePdfFromSections>[0]) => generatePdfFromSections(options)
  })

export const useSaveToEhr = () =>
  useMutation({
    mutationFn: (encounter: IEncounter) => api.saveToEhr(encounter)
  })
