import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { authAxios } from '@/axois'
import { data } from '@/data'
import { IEncounter, ITemplate, ITemplateSection, ITranscriptionResponse } from '@/models'
import { formatTimestamp } from '@/util'

export interface IStore {
  environment: {
    ehr: 'Dentrix' | null
  }
  settings: { transcriptChunksInSeconds: number }
  view: 'Setup' | 'Transcript' | 'Note' | 'Summary' | 'Template' | 'Edit' | 'ProcedureSetup'
  encounter: IEncounter | null
  activeTemplate: ITemplate
  templateValues: { [key: string]: string } | null
  startEncounterTime: number
  wasNoteUpdatedAfterGeneration: boolean
  isAuthenticated: boolean
  licenseKey: string
  requestErrors: { status?: number; message: string } | null
  requestSuccess: string | null

  storeAuthentication(payload: any): void

  clearAuthentication(): void

  updateAccountDetails(payload: string): void

  clearOrganizationInfo(): void

  storeOrganizationInfo(payload: any): void

  validateLicenseKey(licenseKey: string, reValidate?: boolean): Promise<boolean>

  clearAuthenticationAction(decoded?: boolean): Promise<void>

  setEnvironment(environment: IStore['environment']): void

  setView(view: IStore['view']): void

  setEncounter(encounter: IEncounter): void

  updateEncounter(data: Partial<IEncounter>): void

  setActiveTemplate(template: ITemplate): void

  setTemplateValues(values: any): void

  startNewEncounter(): void

  startNewProcedureEncounter(): void

  toggleSection(sectionId: ITemplateSection['id']): void

  addTranscriptionResponse(transcription: ITranscriptionResponse): void
}

const useStoreBase = (set: any): IStore => ({
  environment: { ehr: null },
  settings: { transcriptChunksInSeconds: 20 },
  view: 'Setup',
  encounter: null,
  activeTemplate: data.notesAllType[0],
  templateValues: null,
  startEncounterTime: +new Date(),
  wasNoteUpdatedAfterGeneration: false,
  isAuthenticated: false,
  licenseKey: '',
  requestErrors: null,
  requestSuccess: null,

  setEnvironment: (environment: IStore['environment']) => set({ environment }),
  setView: (view: IStore['view']) => set({ view }),
  setEncounter: (encounter: IEncounter) => set({ encounter }),
  updateEncounter: (data: Partial<IEncounter>) => {
    set((state: IStore) => ({
      encounter: state.encounter ? { ...state.encounter, ...data } : state.encounter
    }))
  },
  setActiveTemplate: template => set({ activeTemplate: template }),
  setTemplateValues: values => set({ templateValues: values }),
  startNewEncounter: () => {
    set({
      view: 'Setup',
      encounter: null,
      activeTemplate: data.notesAllType[0],
      startEncounterTime: +new Date(),
      wasNoteUpdatedAfterGeneration: false,
      templateValues: null
    })
  },
  startNewProcedureEncounter: () => {
    set({
      view: 'ProcedureSetup',
      encounter: null,
      activeTemplate: data.procedureTemplates[0],
      startEncounterTime: +new Date(),
      wasNoteUpdatedAfterGeneration: false,
      templateValues: null
    })
  },
  toggleSection: sectionId =>
    set((state: IStore) => {
      if (!state.encounter) {
        return state
      }

      const deletedSections = state.encounter.deletedSections.includes(sectionId)
        ? state.encounter.deletedSections.filter(id => id !== sectionId)
        : [...state.encounter.deletedSections, sectionId]

      return { ...state, encounter: { ...state.encounter, deletedSections } }
    }),
  addTranscriptionResponse: (transcription: ITranscriptionResponse) =>
    set((state: IStore) => {
      if (!state.encounter) {
        return state
      }

      const hasAtLeastOneSection = Object.keys(state.encounter.transcript?.sections || {}).length > 0
      let startEncounterTime = state.startEncounterTime
      if (!hasAtLeastOneSection) {
        startEncounterTime = +new Date()
      }

      const sections = state.encounter.transcript?.sections ?? {}
      const sectionId = formatTimestamp()
      const section = sections[sectionId]
      const templateSections = state.encounter.transcriptTemplate.sections

      return {
        ...state,
        wasNoteUpdatedAfterGeneration: state?.encounter?.note ? true : false,
        encounter: {
          ...state.encounter,
          startEncounterTime,
          ...(section
            ? {}
            : {
                transcriptTemplate: {
                  ...state.encounter.transcriptTemplate,
                  sections:
                    sectionId === templateSections[templateSections.length - 1].id
                      ? templateSections
                      : [...templateSections, { id: sectionId, name: sectionId, requiredInfo: [] }]
                }
              }),
          transcript: {
            ...state.encounter.transcript,
            sections: {
              ...sections,
              [sectionId]: section ? `${section} ${transcription.transcript}` : transcription.transcript
            }
          }
        }
      }
    }),

  storeAuthentication: (payload: any) =>
    set((state: IStore) => ({
      ...state,
      isAuthenticated: true,
      licenseKey: payload.license_key
    })),
  clearAuthentication: () =>
    set({
      isAuthenticated: false,
      licenseKey: ''
    }),
  updateAccountDetails: (payload: string) =>
    set({
      bolaVersion: payload
    }),
  clearOrganizationInfo: () =>
    set({
      optedBeta: false
    }),
  storeOrganizationInfo: (payload: any) =>
    set({
      optedBeta: payload.betaStatus
    }),

  validateLicenseKey: async (licenseKey: string, reValidate = false): Promise<boolean> => {
    const activateEndpoint = `/license/${licenseKey.trim()}/validate`
    try {
      const response = await authAxios.get(activateEndpoint)
      if (response.data.data.response.is_a_i_scribe) {
        if (!reValidate) {
          set({
            requestSuccess: 'User authenticated'
          })
        }
        set({
          isAuthenticated: true,
          licenseKey,
          requestErrors: null,
          requestSuccess: 'User authenticated'
        })
        return true
      } else {
        set({
          requestErrors: {
            status: response.data.meta.code,
            message: 'Your key is invalid. Please try again.'
          }
        })
        return false
      }
    } catch (error) {
      set({
        requestErrors: {
          message: 'Your key is invalid. Please try again.'
        }
      })
      return false
    }
  },

  clearAuthenticationAction: async (decoded?: boolean) => {
    set({ clearAuthentication: decoded })
    set({ requestErrors: null, requestSuccess: null })
  }
})

export const useStore = create(
  persist<IStore>(useStoreBase, {
    name: 'encounter-store',
    getStorage: () => localStorage
  })
)

export default useStore
