import posthog from 'posthog-js'
import { useEffect, useState } from 'react'

import { Card } from '@/components/ui'
import { useTemplates } from '@/hooks/api'
import { useStore } from '@/store'
import { SocketApi } from '@/services/SocketApi'
import { data } from '@/data'
import { PROCEDURE_TEMPLATE } from '../utils/template.enum'
import back from '/assets/images/back-button.svg'

const Socket = new SocketApi()
export const ProcedureSelect = () => {
  const templates = useTemplates()
  const { encounter, activeTemplate, updateEncounter, setActiveTemplate, setView, startNewEncounter } = useStore()
  const [selectedCardId, setSelectedCardId] = useState<string>(PROCEDURE_TEMPLATE.FILLINGS)
  const builtTemplates: string[] = [
    PROCEDURE_TEMPLATE.FILLINGS,
    PROCEDURE_TEMPLATE.ROOT_CANAL_FILLINGS,
    PROCEDURE_TEMPLATE.CROWN_PERMANENT,
    PROCEDURE_TEMPLATE.CROWN_TEMPORARY
  ]
  useEffect(() => {
    if (activeTemplate) {
      posthog.capture(`template_selected_${activeTemplate.name}`)
    }
  }, [activeTemplate])
  if (!templates.data) {
    return null
  }

  const handleCardClick = (templateId: string) => {
    const selectedTemplate = builtTemplates.includes(templateId)
      ? templates.data['Note'].find(template => template.id === templateId)
      : data.templateNotes[2]
    setSelectedCardId(templateId)
    if (selectedTemplate) {
      setActiveTemplate(selectedTemplate)
      if (encounter) {
        updateEncounter({ templateId })
      }
    }
  }

  const handleBackButton = () => {
    setView('Setup')
    startNewEncounter()
    Socket.finishEncounter()
  }

  return (
    <div className="mt-5">
      <button
        onClick={handleBackButton}
        className="ml-30 fixed left-tenth bg-transparent text-lg text-[#010D83] hover:underline"
      >
        <span className="flex items-center">
          <img src={back} alt="Back icon" className="mr-2" />
          Back
        </span>
      </button>
      <div className="flex flex-col items-center gap-2">
        <div className="text-lg font-semibold text-blue-800">What type of procedure is this note for?</div>
        <div className="text-sm text-gray-400">Please select from the following options and click the mic to begin</div>
      </div>
      <br />
      <div className="grid grid-cols-3 gap-4">
        {templates.data['Note'].map(template => {
          const isActive = selectedCardId === template.id
          return (
            <Card
              key={template.id}
              className={`group flex h-10 cursor-pointer items-center justify-center rounded-lg border-[#D9D9D9] hover:bg-[#5C73E4] ${isActive ? 'bg-[#324FDD]' : 'bg-[#D9D9D9] '}`}
              onClick={() => handleCardClick(template.id)}
            >
              <div className="flex flex-col items-start">
                <div
                  className={`text-sm font-semibold group-hover:text-white ${isActive ? 'text-white' : 'text-black'}`}
                >
                  {template.name}
                </div>
              </div>
              {template.label && (
                <div
                  className={`h-5.5 rounded-sm p-1 text-xs font-semibold ${isActive ? 'bg-[#F3F4F6] text-[#324FDD]' : 'text-white'} ${template.label.style}`}
                >
                  {template.label.name}
                </div>
              )}
            </Card>
          )
        })}
      </div>
    </div>
  )
}
