import { cn } from '@/util'

export const Variable = ({ children, value, isTriggerField = false, optional = false }) => {
  const highlightClass = isTriggerField ? 'bg-[#E0C300] text-black ' : 'bg-blue-500'

  if (value) {
    if (Array.isArray(value)) {
      return (
        <ul className="template-list-variable list-disc pl-6">
          {value.map((v, i) => {
            return (
              <li key={i} className="py-1">
                <span className="rounded-md bg-[#1aa383] p-1 px-2 text-white">{v}</span>
              </li>
            )
          })}
        </ul>
      )
    }
    return <span className={cn('template-text-variable rounded-md bg-[#1aa383] p-1 px-2 text-white')}>{value}</span>
  }

  if (optional) {
    return ''
  }

  return (
    <span className={cn('template-null-variable rounded-md bg-[#5A71E4] p-1 px-2 text-white', highlightClass)}>
      <span className="hidden">(</span>
      {children}
      <span className="hidden">)</span>
    </span>
  )
}
