import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import posthog from 'posthog-js'
import { useEffect, useRef, useState } from 'react'

import { Header } from '@/components/Header'
import { MicButton } from '@/components/MicButton'
import { MicLevel } from '@/components/MicLevel'
import { NoteSidebar } from '@/components/NoteSidebar'
import { ScreenLoader } from '@/components/ScreenLoader'
import { Settings } from '@/components/Settings'
import { SummarySidebar } from '@/components/SummarySidebar'
import { NoteSelect } from '@/components/NoteSelect'
import { TextEditor } from '@/components/TextEditor'
import { TranscriptSidebar } from '@/components/TranscriptSidebar'
import { Label, ScrollArea, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui'
import { useScroll } from '@/hooks/useScroll'
import { SocketApi } from '@/services/SocketApi'
import { IStore, useStore } from '@/store'
import { cn, createNewEncounter } from '@/util'
import { Templates } from './components/templates/Templates'
import { Edit } from './components/Edit'
import { EditSidebar } from './components/EditSidebar'
import { ProcedureSelect } from '@/components/ProcedureSelect'

new SocketApi()
const queryClient = new QueryClient()
export const App = () => {
  const view = useStore(state => state.view)
  const encounter = useStore(state => state.encounter)
  const activeTemplate = useStore(state => state.activeTemplate)
  const setView = useStore(state => state.setView)
  const setEncounter = useStore(state => state.setEncounter)
  const updateEncounter = useStore(state => state.updateEncounter)

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollArea = scrollAreaRef.current
    if (scrollArea) {
      const isOverflowing = scrollArea.scrollHeight > scrollArea.clientHeight
      if (view === 'Transcript' && isOverflowing && encounter?.isTranscribing) {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }, [view, encounter?.isTranscribing])

  const hasTabs = !!encounter?.note || !!encounter?.patientSummary || view === 'Note' || view === 'Summary'
  const isGenerating = !!(encounter?.isGeneratingNote || encounter?.isGeneratingPatientSummary)

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col">
        <Header />
        {isGenerating && <ScreenLoader />}
        <div
          className={cn(
            'flex flex-1 flex-col items-stretch justify-center overflow-hidden md:flex-row-reverse',
            isGenerating && 'hidden'
          )}
        >
          <div
            className={cn(
              'flex w-full flex-col items-stretch justify-start gap-4',
              view === 'ProcedureSetup' ? 'md:w-[750px]' : 'md:w-[600px]' // Procedure setup needs space for 3 card tiles
            )}
          >
            {(view === 'Setup' || view === 'ProcedureSetup') && (
              <div className="flex flex-col justify-center gap-12">
                <div className="flex flex-col gap-3">{view === 'Setup' ? <NoteSelect /> : <ProcedureSelect />}</div>
                {activeTemplate && (
                  <div className="flex flex-col justify-center gap-4">
                    <div className="flex flex-col items-center">
                      <Label className="mb-3">Microphone input level</Label>
                      <MicLevel />
                    </div>
                    <Settings open={isSettingsOpen} onOpenChange={setIsSettingsOpen} />
                  </div>
                )}
              </div>
            )}
            <ScrollArea className="h-full">
              <div className="flex flex-1 flex-col items-stretch justify-start">
                {view === 'Transcript' && <TranscriptSidebar />}
                {view === 'Template' && <TranscriptSidebar />}
                {view === 'Edit' && <EditSidebar />}
                {view === 'Note' && <NoteSidebar />}
                {view === 'Summary' && <SummarySidebar />}
              </div>
            </ScrollArea>
          </div>
          <div
            className={cn(
              'relative flex flex-1 overflow-hidden md:w-[calc(100%-510px)] md:flex-none',
              view === 'Setup' || view === 'ProcedureSetup' ? 'md:w-0' : 'border-t md:border-r md:border-t-0'
            )}
          >
            <ScrollArea ref={scrollAreaRef} className="flex-1">
              <Tabs
                value={view}
                onValueChange={tab => {
                  const view = tab as IStore['view']
                  if (scrollAreaRef.current) {
                    scrollAreaRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                  const events: Record<IStore['view'], string> = {
                    Setup: 'user_clicked_setup_tab',
                    Transcript: 'user_clicked_transcript_tab',
                    Note: 'user_clicked_note_tab',
                    Summary: 'user_clicked_summary_tab',
                    Template: 'user_clicked_template_tab',
                    Edit: 'user_clicked_edit_tab',
                    ProcedureSetup: 'user_clicked_procedure_setup_tab'
                  }
                  updateEncounter({ isTranscribing: false })
                  posthog.capture(events[view])
                  setView(view)
                }}
                defaultValue={view}
              >
                <header className={cn('flex justify-center p-8 pb-4 md:justify-normal', !hasTabs && 'p-0 pb-2')}>
                  <TabsList className={cn(!hasTabs && 'hidden')}>
                    <TabsTrigger value="Transcript">Transcript</TabsTrigger>
                    <TabsTrigger value="Note" disabled={!encounter?.note}>
                      Note
                    </TabsTrigger>
                    <TabsTrigger value="Summary" disabled={!encounter?.note}>
                      Patient Summary
                    </TabsTrigger>
                  </TabsList>
                </header>
                {!isGenerating && (
                  <>
                    <TabsContent value="Template">
                      <Templates />
                    </TabsContent>
                    <TabsContent value="Edit">
                      <Edit />
                    </TabsContent>
                    <TabsContent value="Transcript">
                      <TextEditor type="Transcript" />
                    </TabsContent>
                    <TabsContent value="Note">
                      <TextEditor type="Note" />
                    </TabsContent>
                    <TabsContent value="Summary">
                      <TextEditor type="PatientSummary" />
                    </TabsContent>
                    <div ref={messagesEndRef} />
                  </>
                )}
              </Tabs>
            </ScrollArea>
          </div>
        </div>
      </div>
      {(view === 'Setup' || view === 'Transcript' || view === 'Template' || view === 'ProcedureSetup') &&
        activeTemplate && (
          <div
            className={`pointer-events-none left-0 right-0 z-50 flex items-end justify-center transition-all duration-300 ease-out ${view === 'Setup' || view === 'ProcedureSetup' ? '' : 'fixed bottom-0'}`}
          >
            <div className="pointer-events-auto flex flex-col items-center justify-center">
              <MicButton
                onStart={() => {
                  if (view === 'Setup' || view === 'ProcedureSetup') {
                    posthog.capture('user_clicked_mic_button_from_main_page')

                    if (!activeTemplate) {
                      throw new Error('No active template')
                    }

                    if (activeTemplate?.isHardCoded) {
                      setView('Template')
                    } else {
                      setView('Transcript')
                    }
                    setEncounter(createNewEncounter(activeTemplate, { isTranscribing: true }))
                  }
                }}
                disabled={false}
              />
            </div>
          </div>
        )}
    </QueryClientProvider>
  )
}
