import { Info } from 'lucide-react'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { useEffect, useState } from 'react'

import { Card } from '@/components/ui'
import { useNote } from '@/hooks/api'
import { useStore } from '@/store'

export const NoteSelect = () => {
  const notes = useNote()
  const { encounter, activeTemplate, updateEncounter, setActiveTemplate, setView, startNewProcedureEncounter } =
    useStore()

  const [hoveredNoteId, setHoveredNoteId] = useState<string | null>(null)

  useEffect(() => {
    if (activeTemplate) {
      posthog.capture(`template_selected_${activeTemplate.name}`)
    }
  }, [activeTemplate])

  if (!notes.data) {
    return null
  }

  const handleCardClick = (templateId: string) => {
    switch (templateId) {
      case 'QUICK_NOTE':
      case 'PATIENT_INTAKE':
      case 'SOAP_NOTE': {
        const selectedTemplate = notes.data['Note'].find(template => template.id === templateId)
        if (selectedTemplate) {
          setActiveTemplate(selectedTemplate)
          if (encounter) {
            updateEncounter({ templateId })
          }
        }
        break
      }
      case 'PROCEDURE_NOTE': {
        startNewProcedureEncounter()
        setView('ProcedureSetup')
        break
      }
      default:
        Sentry.addBreadcrumb({
          message: `Unknown noteName: ${templateId}`,
          level: 'warning',
          category: 'Warning'
        })
    }
  }

  const handleMouseOver = (noteId: string) => {
    setHoveredNoteId(noteId)
  }

  const handleMouseLeave = () => {
    setHoveredNoteId(null)
  }

  return (
    <div>
      <div className="mt-5 flex flex-col items-center gap-2">
        <div className="text-lg font-semibold text-blue-800">What type of note would you like to create?</div>
        <div className="text-sm text-gray-400">Please select from the following options and click the mic to begin</div>
        <br />
      </div>
      <div className="grid grid-cols-2 gap-4">
        {notes.data['Note'].map(note => {
          const isActive = activeTemplate?.id === note.id
          const isSpanHovered = hoveredNoteId === note.id

          return (
            <Card
              key={note.name}
              className={`card-class group relative z-10 flex h-40 cursor-pointer items-center rounded-lg border-[#DFE4EA] p-4 ${
                isSpanHovered ? 'bg-[#ECEFFD] text-[#010D83]' : isActive ? 'bg-[#324FDD]' : 'bg-[#F3F4F6]'
              } ${!isSpanHovered && !isActive && 'hover:bg-[#5C73E4]'} transition-colors duration-300`} // Ensure transition for smooth color changes
              onClick={() => handleCardClick(note.id)}
            >
              <span
                className="absolute right-2 top-2 rounded-full bg-white" // Ensure span height is fixed
                onMouseOver={() => handleMouseOver(note.id)}
                onMouseLeave={handleMouseLeave}
              >
                <Info className="text-blue-500" size={16} />
              </span>
              <div className="flex h-full flex-col items-start">
                {' '}
                {/* Ensure the content inside the card is flexible */}
                {isSpanHovered ? (
                  <div className="flex flex-col items-start">
                    <div className="text-sm font-bold">{note.name}</div>
                    <br />
                    <div className="text-sm">{note.description}</div>
                  </div>
                ) : (
                  <>
                    <img
                      src={note.imgName as string}
                      className={`mb-3 group-hover:filter-none ${isActive ? 'filter-none' : 'note-icons'}`}
                    />
                    <div
                      className={`mb-3 text-sm font-semibold text-black group-hover:text-white ${isActive ? 'text-white' : 'text-black'}`}
                    >
                      {note.name}
                    </div>
                    {note.label && (
                      <div
                        className={`h-5.5 rounded-sm p-1 text-xs font-semibold ${isActive ? 'bg-[#F3F4F6] text-[#324FDD]' : 'text-white'} ${note.label.style}`}
                      >
                        {note.label.name}
                      </div>
                    )}
                  </>
                )}
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  )
}
