import posthog from 'posthog-js'
import { useState, useEffect } from 'react'

import bolaLogo from '/assets/bola-logo.svg'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui'
import { useStore } from '@/store'
import { cn } from '@/util'

import { Stepper } from './Stepper'
import { SocketApi } from '@/services/SocketApi'

const Socket = new SocketApi()
const CANNY_APP_ID = import.meta.env.VITE_CANNY_APP_ID

export const Header = () => {
  const view = useStore(state => state.view)
  const startNewEncounter = useStore(state => state.startNewEncounter)
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false)

  useEffect(() => {
    if (window.Canny) {
      window.Canny('initChangelog', {
        appID: CANNY_APP_ID,
        position: 'bottom',
        align: 'right',
        theme: 'light'
      })
    }

    return () => {
      if (window.Canny) {
        window.Canny('closeChangelog')
      }
    }
  }, [view])

  return (
    <header className="flex h-20 items-center justify-between gap-2 border-b px-8 md:h-24">
      <img src={bolaLogo} className="h-8" alt="Bola Logo" />
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <Stepper />
      </div>
      <Dialog
        open={isCloseDialogOpen}
        onOpenChange={open => {
          posthog.capture('user_clicked_close_button')
          setIsCloseDialogOpen(open)
        }}
      >
        <DialogTrigger asChild>
          <Button
            variant="link"
            className={cn('-mr-4 flex-shrink-0', view === 'Setup' && 'pointer-events-none invisible')}
          >
            New Note
          </Button>
        </DialogTrigger>

        <Dialog>
          {view === 'Setup' && (
            <Button variant="link" className="-mr-4 flex-shrink-0" cannyChangelog>
              What's new?
            </Button>
          )}
        </Dialog>

        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>This will discard everything and cannot be undone.</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                posthog.capture('user_cancelled_discard_button')
                setIsCloseDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="destructive"
              onClick={() => {
                posthog.capture('user_confirmed_discard_button')
                setIsCloseDialogOpen(false)
                startNewEncounter()
                Socket.finishEncounter()
              }}
            >
              Yes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </header>
  )
}
